@import "../../../../styles/colors";

.payload-raw-editor-container {
  display: flex;
  flex-direction: column;
}

.payload-raw-editor-container-content {
  flex-grow: 1000;
  background-color: $silver;
}

.payload-raw-editor-container-actions {
  background: $silver;
  flex-grow: 1;
  padding: 10px;
}

//
.json-tree-container {
  font-size: 16px;

}

.json-tree-container label {
  font-weight: normal !important;
}

.json-tree-container .fa {
  color: $teal-base;
}

//
.editorModal .modal-header {
  padding: 15px 15px 5px;
  border-bottom: 1px solid #e5e5e5
}

.editorModal .modal-title {
  font-size: inherit;
  line-height: inherit;
  font-weight: normal;
}

.editorModal .checkbox {
  margin-bottom: 0;
  margin-top: 0;
}

.editorModal #payload {
  margin: -15px;
}

.form-group.headerBox {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 5px;
}


//
.modal .ph-prison {
  border: 1px solid $alloy;
  border-radius: 4px;
  padding: 5px;
}


//
.rjt-value {
  font-size: 14px;
  font-style: normal;
  color: $success-base;
  text-decoration: none;
}

.rjt-key {
  cursor: pointer;
}

.rjt-item {
  font-size: 14px;
  color: $info-base;
}
