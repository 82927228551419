@import "../../../../../../styles/colors";

$xcol-back-color: #e9f8f5;
$xcol-bord-color: #afe6db;

.xemail-block-default-header.xemail-column-header {
    background-color: $xcol-back-color;
}

.xemail-block-default-container.xemail-column-container {
    border-width: 1px;
    border-color: $xcol-bord-color;
}

.width-error {
    background-color: $persimmon-base;
    padding-left: 2px;
    padding-right: 2px;
    color: white;
    border-radius: 5px;
}

.width-error::before{
    content: '⚠ '
}
