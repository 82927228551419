$xsubtemplate-back-color: #f7f0ff;
$xsubtemplate-bord-color: #dcc7f1;

.xemail-block-default-header.xemail-subtemplate-header {
    background-color: $xsubtemplate-back-color;
}

.xemail-block-default-container.xemail-subtemplate-container {
    border-width: 1px;
    border-color: $xsubtemplate-bord-color;
}

.xemail-subtemplate-body {
    padding: 8.5px;
}

.subtemplate-parameter-input {
    width: 100px;
    margin-bottom: 0;
}

.add-button {
    padding: 5px;
    background-color: initial !important;
}

.add-button:hover, .add-button:disabled {
    background-color: initial !important;
}

.parameters-preview {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
    margin-top: 10px;
}

.context-button-icon.partial-template-context-icon {
    line-height: 30px;
}
