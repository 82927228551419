@import '../../../../../../styles/colors';

.dynamicImage {
    display: inline-block;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    padding: 0 4px 0 4px;
    margin: 0 1px 0 1px;
    border: 1px dashed $persimmon-base;
    border-left: 3px double $persimmon-base;
    border-right: 3px double $persimmon-base;
    position: relative;
    min-width: 25px;
    min-height: 20px;
    line-height: 20px;
    background: repeating-linear-gradient(
                    90deg,
                    #fff,
                    #fee 1px,
                    #fee 1px,
                    #fff 2px
    );
}

.dynamicImage::before {
    content: "📷";
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: calc(50% - 10px );
}
