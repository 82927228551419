
.draggable-resource {
    padding: '10px';
}

.draggable-resource-container {
  border: 0.5px solid $platinum;
  border-radius: 3px;
  margin-bottom: 10px;
  background: white;
  padding: 10px 10px 10px 0;
  cursor: grab;
}

.draggable-resource-container:hover {
    border: 0.5px solid $shale;
}
