.left-right-block {
    display: flex;
    justify-content: space-between
}

.left-side, .right-side {
    display: flex;
    white-space: nowrap;
}


