.github-picker {
    width: 187px !important;
}

.github-picker > span > div {
    border: 1px solid #ffffff;
    margin: 2px;
}

.github-picker > span > div > span > div{
    border-radius: 4px;
    border: 1px solid #eee;
}

.color-picker-text-field {
    margin-top: -3px;
}

.color-picker-text-field > input {
    border-radius: 0px 0px 4px 4px;
}