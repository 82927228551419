@import "../../../../../styles/colors";

.flex-full-width {
    flex-grow: 10000;
    text-align: left;
}

// HEADER
// ------------------------------------------------------------------------------------------------------

.xcsv-block-default-header {
    display: flex;
    font-size: 10px;
    text-align: center;
    padding-left: 1px;
    padding-top: 2px;
}

// HEADER BUTTONS
// ------------------------------------------------------------------------------------------------------

.xcsv-header-button {
    flex-grow: 1;
    color: $granite-base;
    cursor: pointer;
    background-color: transparent;
    padding: 2px;
    padding-top: 0;
    border-radius: 2px;
    margin: 1px;
}

.xcsv-header-button .fa-clone:hover{
    color: $info-base;
}

.xcsv-header-button:hover {
    color: $info-base;
}

.xcsv-header-button .fa-trash:hover {
    color: $designer-remove-color;
}

// CONTAINER
// ------------------------------------------------------------------------------------------------------

.xcsv-block-default-container {
    position: relative;
    margin: 1px;
    border-width: 1px;
    border-style: dotted;
}

.xcsv-block-default-container:hover {
    border-style: solid;
}


// d-n-d placeholders
// ------------------------------------------------------------------------------------------------------

.xcsv-vertical-drop-target {
    border: 0;
    z-index: 0;
    margin: 0;
    font-size: 10px;
    height: 100%;
}

.xcsv-horizontal-drop-target {
    border: 0;
    z-index: 0;
    margin: 0;
    font-size: 4px;
}

.xcsv-dragging-can-drop {
    background: repeating-linear-gradient(
                    90deg,
                    $designer-drag-allowed,
                    #fff 1px,
                    #fff 1px,
                    $designer-drag-allowed 2px
    );
}

.xcsv-dragging-can-drop-and-over {
    background: $designer-drag-current;
}
