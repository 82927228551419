.ux-one-half-icon .ij-icon.ij-icon-2x {
    width: 24px;
    height: 24px;
    padding-top: 4px;
    padding-left: 2px;
    cursor: pointer;
}

.ux-one-half-icon .ij-icon.ij-icon-2x embed, .ux-one-half-icon .ij-icon.ij-icon-2x svg {
    transform: scale(calc(24 / 24));
}
