@import "colors";

%gray-box {
    background-color: $silver;
    border: 1px solid $alloy;
}

%white-box {
    background-color: #fff;
    border: 1px solid #fff;
}

%yellow-box {
    background-color: #ffe;
    border: 1px solid #eed;
}

%rounded {
    border-radius: 5px;
}

%big-margin-bottom {
    margin-bottom: 20px;
}

%medium-margin-bottom {
    margin-bottom: 10px;
}

%big-padding {
    padding: 20px;
}

%medium-padding {
    padding: 10px;
}

%bottom-right-content {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

%full-content {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}
