.attachment-field {
    border: 1px solid white;
    padding: 2px;
    margin-bottom: 2px;
}

.attachment-field-input {
    flex-grow: 1;
    padding-top: 2px;
    padding-left: 4px;
}

.attachment-add-button-wrap {
    padding: 2px;
    padding-left: 0px;
    margin-bottom: 2px;
}

.attachment-add-button {
    border: 1px solid $alloy;
    background-color: $silver;
    cursor: pointer;
    padding: 1px 3px;
    border-radius: 5px;
}

.attachment-add-button:hover {
    background-color: $white;
}

.attachments {
}

.attachment-field-filename {
    font-weight: 600;
}

.attachment-field-contenttype {

}

.attachment-field-content {

}

.xemail-headers {
    font-size: 12px;
    padding: 2px;
    width: 100%;
    vertical-align: bottom;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.xemail-headers .field {
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 2px;
    padding: 4px;
}

.xemail-headers .field-caption {
    min-width: 60px;
    line-height: 20px;
    font-weight: 600;
}

.xemail-headers .field-input {
    flex-grow: 1;
}

.xemail-headers .field-input-group {
    display: flex; // as container
    flex-grow: 1;  // as item in the parent container
}

.xemail-headers .from-field {
    width: 100%;
}

.xemail-headers .from-field .at {
    padding: 0 3px 0 3px;
    font-size: 16px;
}

// 1,1,2 -> 25%,25%,50%
.xemail-headers .field-from-1 {
    flex-grow: 1;
}

.xemail-headers .field-from-2 {
    flex-grow: 1;
}

.xemail-headers .field-from-3 {
    flex-grow: 2;
}

.xemail-headers .field-input-group .at,
.xemail-headers .field-input-group .separator {
    line-height: 20px;
    min-width: 20px;
    font-weight: 600;
    text-align: center;
    font-size: 14px;
}

.xemail-headers .field-select {
    -webkit-appearance: none;
    outline: none;
    height: 21px;
    width: 100%;
    border-color: #bababa;
    border-radius: 1px;
    padding-left: 2px;
    cursor: pointer;
}

.expand-headers-button {
    text-align: right;
    cursor: pointer;
}


.handlebarsTextBox {
    border: 1px solid transparent;
    border-radius: 2px;
}
