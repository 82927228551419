@import "colors";
@import "overlay";
@import "drag";

html, body, #root, #main-router {
    height: 100%;
}

#main-router {
    display: flex;
    flex-direction: column;
}

.popover {
    max-width: 600px;
}

.comment-count-badge {
    line-height: normal;
    top: 7px;
    right: auto;
}

.ij-icon {
    margin-bottom: 2px
}

.active-template-name {
    line-height: 1.286;
    color: $persimmon-base;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 500px;
    padding: 10px 20px;
    margin-top: 6px;
    text-align: right;
}

.active-template-name-full {
  @extend .active-template-name;
    width: 100%;
    padding-left: 0px;
    margin-top: 0px;
}

.nav-links-separator {
    padding: 10px 10px;
    line-height: 1.286;
    margin-top: 6px;
    color: $alloy;
}

.popover-content > .email {
    min-height: auto !important;
}

.placeholder {
    border-radius: 3px;
    overflow: hidden;
    vertical-align: middle;
    padding: 0 4px 0 4px;
    margin: 0 2px 0 2px;
    border: 1px solid #0088a9;
    color: #000;
    background: -webkit-repeating-linear-gradient(left, #fff, #eef 1px, #eef 1px, #fff 2px);
    background: repeating-linear-gradient(90deg, #fff, #eef 1px, #eef 1px, #fff 2px);
}

.text-card .card-block {
    margin: 4em;
}