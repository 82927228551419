@import "../../../../../styles/colors";
@import "../../../../../styles/mcpColors";
@import './defaultBlocks';

@import "../blocks/XButton/XButton.scss";
@import "../blocks/XColumn/XColumn.scss";
@import "../blocks/XImage/XImage.scss";
@import "../blocks/XLoop/XLoop.scss";
@import "../blocks/XCondition/XCondition.scss";
@import "../blocks/XMain/XMain.scss";
@import "../blocks/XRow/XRow.scss";
@import "../blocks/XText/XText.scss";
@import "../blocks/XVerticalSpacer/XVerticalSpacer.scss";
@import "../blocks/XSubTemplate/XSubTemplate.scss";

@import '../plugins/ckeditorDynamicImage/dynamicImage.scss';
@import '../plugins/ckeditorPlaceholderPlugin/placeholder.scss';

@import './XEmailHeaders.scss';

div[disabled] {
    pointer-events: none;
}

div[disabled] .ck.ck-icon {
    color: lightgray;
    fill: lightgray;
}

div[disabled] .ck.ck-button {
    color: lightgray;
    fill: lightgray;
}

/*
 * 65px is the 'actions bar'
 * 102px is the MCP header
 * 36px is the email toobar
 */
.xboard {
    font-style: wf_segoe-ui_normal, "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif, serif, EmojiFont;
   // height: calc(100vh - 65px - 102px - 36px);
    height: calc(100vh - 55px);
    min-width: 100%;
    overflow: auto;
}

.xboard.embedded {
    height: calc(100vh - 65px - 36px);
}

.xemailWrapper {
    padding: 5px;
}

.xemail {
    display: block;
    min-height: 100px;
    overflow: hidden;
}

.card-block p {
    margin-bottom: 13px !important;
}

.xboard-header {
    background-color: #eee;
}

.xemail-designer-type .checkbox {
    margin: 5px;
}

.xboard .card-block {
    padding: 0;
}

.ace-editor-readonly .ace-github {
    background-color: #f5f5f5 !important;
    color: #777
}
