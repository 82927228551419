.xcsv-row-container {
    border-color: transparent;
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
}

.xcsv-row-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
