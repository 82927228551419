.trdelnik-saving-overlay-visible {
    display: block;
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #fff;
    opacity: 0.5;
}

.trdelnik-saving-overlay-hidden {
    display: none;
}

.trdelnik-saving-overlay-spinner {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
