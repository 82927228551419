.xemail-vspacer-header {
    text-align: center;
}

.xemail-vspacer-header-item {
    flex-grow: 1;
}

.outer {
    display: table;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.inner {
    margin-left: auto;
    margin-right: auto;
}

.xemail-vspacer-container {
    padding: 0;
    border: 1px dotted rgba(0, 0, 255, 0.4);
}

.xemail-vspacer-container:hover {
    border-style: solid;
}

.xemail-vspacer {
    padding: 0;
    margin: 0 5px 2px 5px;
    border-radius: 0;
}

.xemail-vspacer > input {
    background-color: transparent;
    border-color: transparent;
}

.xemail-vspacer {
    text-align: center;
}
