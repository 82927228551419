$xrow-back-color: #fbe7f1;
$xrow-bord-color: #f0a5cb;

.xemail-row-header {
    background-color: $xrow-back-color;
    flex-grow: 1;
    flex-direction: column;
}

.xemail-row-header .flex-full-width {
    padding: 2px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.xemail-row-header .xemail-header-button {
    background: transparent;
}

.xemail-block-default-container.xemail-row-container {
    border-color: $xrow-bord-color;
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
}

.xemail-row-body {
    flex-grow: 1000;
}

.x-action {
    cursor: pointer;
}

.xrow-table {
    border-collapse: separate;
}
