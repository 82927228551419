@import "../../../../styles/colors";
@import "../../../../styles/boxes";

.notifications {
    @extend %medium-padding;
    @extend %medium-margin-bottom;
    background-color: $designer-default-background;
    border: 3px solid $info-base;
}

.action-bar {
    @extend %medium-margin-bottom;
    text-align: right;
}

.email-context-button-icon {
    color: $shale;
    font-size: 1.5em;
    width: 20px;
}

.email-context-button-icon:hover {
    color: $info-base;
    cursor: pointer;
}

.email-error-details {
    border: 1px solid $designer-remove-color;
    background-color: $designer-remove-background;
    overflow: hidden;
    text-overflow: ellipsis;
}

.email-status-cell-text {
    padding-top: 18px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.emailing-badge {
    margin: 2px;
}
