@import "../../../../../../styles/colors";

.xemail-text-header {
    background: $horizon;
}

.xemail-text-container {
    background-color: #fff;
    border-color: $shale;
    word-break: break-all;
}

.ck-editor__editable {
}

.ck.ck-editor__editable_inline {
    padding: 2px;
    margin-top: 2px;
}

.ck mark {
    padding: 0;
    background-color: unset;
}

/* ckeditor5-highlight setup */

/* Pens */
.edie-color-p-F0563A {
    color: #F0563A;
}
.edie-color-p-4f5d75 {
    color: #4f5d75;
}
.edie-color-p-697582 {
    color: #697582;
}
.edie-color-p-C4CDD6 {
    color: #C4CDD6;
}
.edie-color-p-0088A9 {
    color: #0088A9;
}
.edie-color-p-006F8A {
    color:#006F8A;
}
.edie-color-p-35AD96 {
    color:#35AD96;
}
.edie-color-p-22917E {
    color: #22917E;
}
.edie-color-p-127769 {
    color: #127769;
}
.edie-color-p-FFA23F {
    color: #FFA23F;
}
.edie-color-p-FFFFFF {
    color: #FFFFFF;
}

/* Markers */
.edie-color-m-F0563A {
    background-color: #F0563A !important;
}

.edie-color-m-FFFF00 {
    background-color: #ffff00 !important;
}

.disabled-text-ck {
    background-color: #eee;
    font-style: italic;
    color: #aaa;
    padding: 5px;
}

.placeholder-cover {
    background-color: #888;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    padding-right: 6px;
}
.placeholder-cover:after {
    content: ' ... ';
}

/* Override MCP styles */
.ck-content code {
    color: unset;
    font-size: unset;
    font-family: monospace;
}

/* Override ckeditor styles */
.ck-widget {
    outline-width: 2px !important;
    position: static !important;
}
