@import '../../../../../../styles/colors';

.ed-ck-placeholder {
    border-radius: 3px;
    overflow: hidden;
    vertical-align: middle;
    padding: 0 4px 0 4px;
    margin: 0 2px 0 2px;
    border: 1px solid $teal-base;
    background: repeating-linear-gradient(
                    90deg,
                    #fff,
                    #eef 1px,
                    #eef 1px,
                    #fff 2px
    );
}

.ed-ck-placeholder a {
    color: white;
}

.ed-ck-placeholder::after {
    content: attr(data-display);
}

.ed-ck-placeholder.ph-open,
.ed-ck-placeholder.ph-close {
    border-color: $shale;
}

.ed-ck-placeholder.ph-open {
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
}

.ed-ck-placeholder.ph-close {
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
}

.ed-ck-placeholder.ph-condition {
    border-color: $shale;
    background: repeating-linear-gradient(
                    90deg,
                    #fff,
                    #eee 1px,
                    #eee 1px,
                    #fff 2px
    );
}

.ed-ck-placeholder.ph-formula {
    border-color: $success-base;
    background: repeating-linear-gradient(
                    90deg,
                    #fff,
                    #efe 1px,
                    #efe 1px,
                    #fff 2px
    );
}

.ck.ck-editor__editable .ck-widget .ck-widget__type-around__button {
    display: none;
}

.ck.ck-reset_all.ck-widget__type-around {
    display: inline;
}