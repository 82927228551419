.xemail-block-default-container.xemail-button-container {
    border-color: rgba(0, 0, 255, 0.4);
}

.xemail-button-container {
    .btn.btn-outline-dark:hover {
        color: inherit !important;
        background-color: inherit !important;
    }
}

.xemail-button {
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
