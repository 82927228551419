@import "../../../../../../styles/colors";

.xemail-block-default-header.xemail-loop-header {
    text-align: left;
}

.xemail-block-default-container.xemail-loop-container {
    border-color: deepskyblue;
}

.repeater-demo {
    border: 1px solid $platinum;
    padding: 10px;
    background-color: $silver;
    color: $shale
}

.repeater-demo-group-begin {
    font-family: monospace;
    padding: 5px;
}

.repeater-demo-placeholder::before {
    content: '...'
}

.repeater-demo-placeholder {
    padding-left: 5px;
    margin-left: 5px;
}

.repeater-demo-group-end {
    font-family: monospace;
    padding: 5px;
}
