@import "../../../../../../styles/colors";

.xcsv-block-default-header.xcsv-loop-header {
    text-align: left;
    font-size: smaller;
    padding: 4px;
    border: 1px solid #eee;
    background-color: white;
}

.xcsv-block-default-container.xcsv-loop-container {
    border-color: transparent;
}

.xcsv-repeater-demo {
    border: 1px solid $platinum;
    padding: 10px;
    background-color: $silver;
    color: $shale
}

.xcsv-repeater-demo-group-begin {
    font-family: monospace;
    padding: 5px;
}

.xcsv-repeater-demo-placeholder::before {
    content: '...'
}

.xcsv-repeater-demo-placeholder {
    padding-left: 5px;
    margin-left: 5px;
}

.xcsv-repeater-demo-group-end {
    font-family: monospace;
    padding: 5px;
}
