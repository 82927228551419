@import "colors";

.template-edit-badge {
    float: right;
}

.comment-drawer-button {
    float: right;
    font-size: 20px;
    padding-top: 0 !important;
}

.pagination {
    margin: 0px;
    text-align: center;
}

.tag.pull-right {
    margin-top: 5px;
}

.btn-fa-inline {
    font-size: 1.2em;
    color: $shale;
    cursor: pointer;
    position: relative;
    padding-right: 8px;
    display: inline-block;
    margin-top: 9px;
}

li:hover .btn-fa-inline {
    color: $teal-base;
}

.highlight {
    padding: 0px;
    background-color: $designer-highlighted;
    color: inherit;
}

.welcome-heading {
    color: $persimmon-base;
}

.navbar-fluid .navbar {
    margin-bottom: 0px;
}

.margin-top {
    margin-top: 18px;
}

.item-template-name-section h5{
    margin-bottom: 0;
}


.item-template-id{
    color: $alloy;
    font-size: 10px;
}