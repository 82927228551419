@import "../../../../../../styles/colors";

$ximage-back-color: #fef2e7;
$ximage-bord-color: #fcd8b6;

.xemail-block-default-header.xemail-image-header {
    background-color: $ximage-back-color;
}

.xemail-block-default-container.xemail-image-container {
    border-color: $ximage-bord-color;
}

.xemail-image .img-not-available{
    border: 1px solid $alloy;
    background: url(https://static.ux.cimpress.io/assets/icons/landscape-mode-l.svg);
    background-color: $silver;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    min-width: 100px;
}

.ximage-radiogroup {
    margin-bottom: 15px;
}

.ximage-upload-form {
    margin-bottom: 25px;
}

.ximage-upload-form input{
    display: none;
}

.ximage-upload-form label{
    margin-right: 10px;
}

.image-condition-demo {
    border: 1px solid $platinum;
    padding: 10px;
    background-color: $silver;
    color: $shale
}

.image-condition-demo-group-begin {
    font-family: monospace;
    padding: 5px;
}

.image-condition-demo-placeholder {
    padding-left: 5px;
    margin-left: 5px;
}

.image-condition-demo-group-end {
    font-family: monospace;
    padding: 5px;
}
