@import "mcpColors";

// Email Designer colors
// --------------------------------------
$designer-blue: #188ec5;

$designer-default-background: #fff;
$designer-default-border: #fff;

$designer-button: rgb(104, 155, 175);

// the 'remove' colors are mainly used for 'delete' / 'remove' buttons (on hover)
$designer-remove-color: #a00;
$designer-remove-background: #fee;

$designer-text-section-color: rgb(104, 155, 175);

// overlay colors (drag-n-drop)
$designer-drag-allowed: $success-base;
$designer-drag-current: $info-darkest;

// highklight
$designer-highlighted: yellow;

// action bar
$designer-action-bar-color: #f3f3f3;
