@import "../../../../styles/colors";

.templateActionsBar {
    border-top: 1px solid $silver;
    background-color: $designer-default-background;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.templateActionsBar .comment-drawer-button .comment-count-badge {
    right: 5px;

}

.templateActionsBar .comment-drawer-button {
    font-size: inherit;
    padding-top: 0px;
}

.templateActionsBar .actionButton:hover {
    background-color: $designer-action-bar-color;
    border: 1px solid $designer-action-bar-color;
}

.templateActionsBar .actionButton.actionButtonDelete:hover {
    background-color: $designer-remove-background;
    border: 1px solid $designer-remove-background;
}

.templateActionsBar .actionButton {
    height: 60px;
    border: 1px solid $designer-default-background;
    border-right-color: $silver;
    border-bottom-color: $silver;
    text-align: center;
    display: grid;
    padding: 5px 10px 5px 10px;
    min-width: 100px;
}

.templateActionsBar .actionButton.actionButtonDelete:hover > .actionIcon,
.templateActionsBar .actionButton.actionButtonDelete:hover > .actionDescription {
    color: $designer-remove-color;
}

.templateActionsBar .actionButton:hover > .actionIcon,
.templateActionsBar .actionButton:hover > .actionDescription,
.templateActionsBar .actionButton:hover .fa-comments-o {
    transition: color 0.2s ease;
    color: $designer-blue;
}

.templateActionsBar .actionButton.disabled > .actionIcon,
.templateActionsBar .disabled .actionButton > .actionIcon,
.templateActionsBar .actionButton.disabled > .actionDescription,
.templateActionsBar .disabled .actionButton > .actionDescription,
.templateActionsBar .actionButton.disabled .fa-comments-o,
.templateActionsBar .disabled .actionButton .fa-comments-o {
    opacity: 0.5;
    color: $shale;
    cursor: default;
}

.templateActionsBar .actionIcon, .templateActionsBar .fa-comments-o {
    color: $shale;
    font-size: 27px;
    margin: auto;
    max-height: 27px;
}

.templateActionsBar .actionDescription {
    color: $shale;
    padding-top: 3px;
    font-size: 14px;
    alignment: left;
}

.templateActionsBar .modal {
    overflow-y: scroll;
}

.minWidth{
    min-width: 150px;
}