@import "../../../../../../styles/colors";
@import "../../../../../../styles//mcpColors.scss";

$xcsv-back-color: $sky-base;
$xcsv-bord-color: $sky-darkest;

.csv-cell-container {
    padding: 0 !important;
    border-color: $xcsv-bord-color !important;
    border-radius: 2px;
    background-color: $designer-default-background;
    overflow: visible;
    width: 100%;
}

.csv-cell-container:hover {
    border-style: solid !important;
}

.cell-text-input {
    margin-bottom: 0;
}

.cell-text-input .form-control {
    border: 0;
}

.xcsv-block-default-header.xcsv-cell-header {
    background-color: $xcsv-back-color;
}
