@import "../../../styles/colors";
@import "../../../styles/boxes";

.templateSampleBlock {
    @extend %white-box;
    @extend %rounded;
    @extend %big-margin-bottom;
    @extend %big-padding;
    border-bottom-color: $silver;
}

.templateSampleBlock:hover {
    border: 1px solid $slate;
}

.templateDescription {
    white-space: normal !important;
    padding-left: 20px;
    position: relative;
}

.templateDescription .btn {
    @extend %bottom-right-content;
}

.templateDescription h5 {
    color: $persimmon-base;
}

.templateSampleBlock.selected {
    @extend %yellow-box
}

.templateSample img {
    @extend %rounded;
    border: 1px dotted $alloy;
}

.step-body {
    @extend %big-margin-bottom;
    @extend %big-padding;
    border: 1px solid $silver;
    margin-top: 10px;
}
