@import "../../../../../styles/colors";
@import './XCsvDefaultBlocks';

@import "../blocks/XCsvLoop/XCsvLoop.scss";
@import "../blocks/XCsvMain/XCsvMain.scss";
@import "../blocks/XCsvRow/XCsvRow.scss";
@import "../blocks/XCsvCell/XCsvCell.scss";

div[disabled] {
    pointer-events: none;
}

div[disabled] .ck.ck-icon {
    color: lightgray;
    fill: lightgray;
}

div[disabled] .ck.ck-button {
    color: lightgray;
    fill: lightgray;
}

.xCsvBoard {
    font-style: wf_segoe-ui_normal, "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif, serif, EmojiFont;
    height: calc(100vh - 102px);
    min-width: 100%;
    width: fit-content;
    background-color: white;
}

.xCsvBoard.embedded {
    height: calc(100vh - 65px);
}

.xcsvWrapper {
    padding: 5px;
}

.xcsv {
    display: block;
    position: relative;
    min-height: 100px;
    overflow: hidden;
}

.card-block p {
    margin-bottom: 13px !important;
}

.xcsv-designer-type .checkbox {
    margin: 5px;
}

.xcsvBoard .card-block {
    padding: 0;
}

.ace-editor-readonly .ace-github {
    background-color: #f5f5f5 !important;
    color: #777
}
