@import "../../styles/colors";
@import "./components/payload/index";

body {
    /* Minimum width */
    min-width: 1000px;
    /* Firefox will set width as auto */
    width: auto !important;
    /* As IE6 ignores !important it will set width as 1000px; */
    width: 1000px;

    overflow-y: auto;
}

.navbar .comment-drawer-button {
    padding-top: 12px;
    padding-right: 4px;
    font-size: 20px;
    margin-top: 10px;
}

.app {
    flex-grow: 1;
    width: 100%;
    height: calc(100% - 103px);
    display: flex;
}
.app.embedded {
    height: 100%;
    width: 100%;
    display: flex;
}

.appContent {
    flex-grow: 1;
}

.boardWrapper {
    height:100%;
}

.notifications {
    position: absolute;
    bottom: 62px;
    right: 10px;
    z-index: 1030;
}

.no-padding {
    padding: 0px !important;
}

.actionButton {
    cursor: pointer;
}

.disabled .actionButton, s.actionButton.disabled {
    cursor: default;
}

.actionButton .drawer-content {
    text-align: left;
}

.testMessageArrayContainer {
    width: 100%;
    padding: 4px;
    display: inline-block;
    min-height: 50px;
    border: 1.5px solid $silver;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px;
}

.testMessageArrayItem {
    width: 100%;
    padding: 4px;
    display: inline-block;
    min-height: 50px;
    border: 1.5px solid $silver;
    border-radius: 4px;
    text-align: center;
    margin-top: 15px;
}

.testMessageArrayContainer:focus-within, .testMessageArrayItem:focus-within {
    border-color: $info-base;
}

.testMessageArrayContainer button {
    margin-top: 15px;
    width: calc(100% - 9px);
}

.editorModal .modal-content {
    max-height: unset;
}

.expanding-error-message i {
    margin-left: 10px;
    margin-right: 2px;
    line-height: 40px;
}

.details-block {
    margin-top: 20px;
    white-space: pre-wrap;
    text-align: left;
}

/********************** PROPERTIES BAR ********************/
.propertyBar select {
    -webkit-appearance: none;
}

.propertyTitle {
    margin-bottom: 16px;
}

.react-custom-trigger {
    height: 50px;
}

/********************** BOARD ********************/
.board h1,
.board h2,
.board h3,
.board h4,
.board h5,
.board h6,
.board span,
.board p {
    font-weight: inherit;
    font-family: inherit;
}

.raw-board {
    border: 1px solid $silver;
}

.emailWrapper {
    padding: 30px 20px 30px 20px;
}

.errorLayout {
    margin: 20px;
    padding: 50px;
    text-align: center;
}

.errorLayout i {
    font-size: 50px;
}

.errorLayout h1 {
    color: $persimmon-base;
}

.errorData {
    background: $silver;
    border: 1px solid $alloy;
    border-radius: 2px;
    padding: 10px;
    margin: 10px;
}

/*
 * 65px is the 'actions bar'
 * 102px is the MCP header
 * 36px is the email toobar
 */
 .board {
    font-style: wf_segoe-ui_normal, "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif, serif, EmojiFont;
}

/* in embedded mode/profile, there is no header */
.board.embedded {
    height: calc(100vh - 65px - 36px);
}

/* Raw template do not have toolbar (for now)... */
.board.raw-board {
    flex-grow: 1;
}

.board.raw-board.embedded {
    height: calc(100vh - 65px);
}

.board.raw-board.toolbar {
    height: calc(100vh - 65px - 102px - 80px);
}

.board.raw-board.toolbar.embedded {
    height: calc(100vh - 65px - 80px);
}

.expanded + .board.raw-board.toolbar {
    height: calc(100vh - 65px - 102px - 180px);
}

.expanded + .board.raw-board.toolbar.embedded {
    height: calc(100vh - 65px - 180px);
}

.email {
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
    display: block;
    border-radius: 4px;
    position: relative;
    min-height: 100px;
}

.section-panel {
    border: 1px dotted $designer-text-section-color;
    cursor: move;
    z-index: 1;
}

.section-panel:hover {
    border-style: solid;
}

.loadingLayout {
    text-align: center;
    margin-top: 15%;
}

.containerWrapper {
    height: 100%;
    overflow: visible;
}

.DraftEditor-root {
    overflow: hidden;
    height: 100%;
}

.DraftEditor-editorContainer {
    height: 100%;
}

.public-DraftEditor-content {
    height: 100%;
}

.board-split {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.board-split .gutter {
    background-color: #e5e7eb;
    background-repeat: no-repeat;
    background-position: 50%;
}

.board-split .gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
    transition: 0.1s;
}

.board-split .gutter.gutter-horizontal:hover {
    background-color: #9f9f9f;
}

.xsettings {
    min-width: 300px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
}

.xsettings .card.card-layout {
    flex-grow: 1;
    background-color: unset;
}

.textToolbar {
    display: inline-flex;
    background-color: $designer-default-background;
    top: -42px;
    position: absolute;
    border: 1px solid;
    border-radius: 3px;
}

.textToolbar > span, .textToolbar > div {
    padding: 6px;
    font-size: 16px;
    color: $slate;
    margin: auto;
    cursor: pointer;
}

.textToolbar input {
    width: 42px;
}

.resizing {
    overflow: hidden !important;
}

.imageAlign-left {
    float: left;
}

.imageAlign-center {
    margin-left: auto;
    margin-right: auto;
}

.imageAlign-right {
    float: right;
}

.email-board-toolbar {
    font-size: 12px;
    background-color: $designer-default-background;
    padding: 10px 10px 5px 10px;
    width: 100%;
    vertical-align: bottom;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.email-board-toolbar .field {
    display: flex;
}

.email-board-toolbar label {
    width: 80px;
}

.email-board-toolbar .field-input {
    width: 100%;
    margin: 0px 0px 3px 0px;
}

.email-board-toolbar .field .separator {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
}

.email-board-toolbar .field-input.from-name {
    width: 50%;
}

.email-board-toolbar .field-input input {
    height: 25px;
    padding: 10px;
}

.email-board-toolbar .from-field {
    width: 100%;
}

.email-board-toolbar .from-field .at {
    padding: 0px 3px 0px 3px;
    font-size: 16px;
}

.email-board-toolbar .field-select {
    -webkit-appearance: none;
    outline: none;
    height: 23px;
    width: 60%;
    border-color: #bababa;
    border-radius: 1px;
    padding-left: 2px;
}

.expand-properties-button {
    text-align: center;
    cursor: pointer;
}

#background {
    margin-top: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: yellow;
    z-index: -100;
}

.context-button-icon {
    color: $shale;
    line-height: 40px;
    font-size: 1.5em;
}

.context-button-icon:hover {
    color: $info-base;
    cursor: pointer;
}

.tabCardEditors .card-block {
    padding: 0;
}

.content-icon {

}

.rct-node-icon {
    color: $teal-base;
}

.rct-node-icon {
    padding-right: 0;
}

.react-grid-layout {
    padding: 0;
}

.linkTypeRadioGroup .checkbox{
    margin-top: 0px;
}


.content-icon {
    color: $persimmon-base;
}

.optionsPanel {
    height: 100%;
    min-height: 100%;
    width: 315px;
    position: relative;
}

.optionsPanel > .card {
    height: 100%;
    overflow-y: auto;
    border-bottom-width: 0;
    box-shadow: none;
}

.resourcesList .card {
    border-bottom-width: inherit;
}

.optionsPanel .card-block {
    background-color: $designer-default-background;
}

.ResourcesAvailablePayloadTextArea {
    display: block;
    resize: none;
    width: 100%;
    min-height: 40vh;
    margin: -15px;
    margin-bottom: 5px;
}

.elements-row {
    display: flex;
    justify-content: space-between;
}

.element-block {
    flex-grow: 1;
    min-width: min-content;
}

.sidebar {
    max-width: 60px;
    min-width: min-content;
    align-items: center;
    overflow-y: auto;
}

.sidebar.active {
    min-width: 165px;
}

.sampleData-btn{
  border: 0.5px solid $platinum;
  padding: 10px;
  background: white;
  cursor: pointer;
  width: max-content;
}

.sideNav-icon{
  font-size: 20px;
  font-weight: 550; 
}

.payload-icon{
  font-size: 18px;
  color: $teal-base;
  padding-left: 4px;
}

.editData-title{
  color: $teal-base
}

.horizontal-seperator{
  border-color: black
}

#sidebar {
  background: $silver;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 10px;
}

.properties-card{
  background: $silver;
  overflow-y: scroll; 
}

.full-width{
  width: 100%
}

.properties-header{
    display: flex;
    padding-top: 15px;
    height: 40px;
    margin: 0 15px 5px;
}

.properties-header + .card .card-block{
    padding-top: 5px;
}
